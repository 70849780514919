import styled from '@emotion/styled';
import * as React from 'react';
import sizes from 'style/sizes';
import { mq } from 'style/variables';

type ProjectCardColProps = {
  col: 1 | 2 | 3;
};

const Container = styled.div<ProjectCardColProps>`
  display: grid;
  ${mq.onlypc} {
    gap: ${sizes.margin[120]} ${sizes.margin[80]};
    ${props => props.col === 2 && `grid-template-columns: 1fr 1fr;`};
    ${props => props.col === 3 && `grid-template-columns: 1fr 1fr 1fr;`};
  }
  ${mq.onlysp} {
    padding: 0 ${sizes.margin[32]};
    gap: ${sizes.margin[48]};
  }
  & > * {
    ${mq.onlysp} {
      width: 100%;
    }
  }
`;

const ProjectCardCol: React.FC<ProjectCardColProps> = props => <Container {...props}>{props.children}</Container>;

export default ProjectCardCol;
