import styled from '@emotion/styled';
import { EnHeading } from 'components/atoms/enHeading';
import { ProjectCard } from 'components/molecules/projectCard';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import FadeUpItem from 'components/organisms/fadeUpItem';
import FadeUpSection from 'components/organisms/fadeUpSection';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import ProjectCardCol from 'components/organisms/projectCardCol';
import { graphql } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import sizes from 'style/sizes';
import { breakpoint, mq } from 'style/variables';
import { ProjectsIndexQuery } from 'types/graphql-types';
import SEO from 'util/seo';
// import { ViewMoreWrapper } from 'components/molecules/viewMoreWrapper';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: ProjectsIndexQuery;
}

export const DISPLAY_COUNT_OF_PICKUP_PROJECTS = 2;

const TitleSection = styled('div')`
  width: 100%;
  ${mq.onlypc} {
    padding-top: ${sizes.margin[180]};
    padding-bottom: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    padding-top: ${sizes.margin[155]};
    padding-bottom: ${sizes.margin[64]};
    padding-left: ${sizes.margin[32]};
    width: unset;
  }
`;

const ProjectArea = styled.div`
  display:grid;
  gap ${sizes.margin[120]};
  ${mq.onlysp} {
    gap: ${sizes.margin[48]};
  }
`;

const categories = [
  'ALL',
  'SERVICE DESIGN',
  'DIGITAL PRODUCT',
  'DIGITAL BRANDING',
  'EXPERIENCE DESIGN',
  'DESIGN OPS',
] as const;

type CategoryType = typeof categories[number];

// const initailShowCount = 6;
// const upCount = 6;

const Pages: React.FC<IndexPageProps> = ({ data }) => {
  const [category, setCategory] = useState<CategoryType>('ALL');
  // const [showCount, setShowCount] = useState(initailShowCount);
  // const [increment, setIncrement] = useState(0);

  const projectsOldLayout = data.allShifterProjectsData.edges;
  const projectsNewLayout = data.allShifterProjectsJpData.edges;
  const projects = projectsNewLayout.concat(projectsOldLayout);
  const pickups = data.shifterPickupProjects?.acf?.pickup;
  const categoryTabs = useMemo(() => {
    return [
      {
        text: 'ALL',
        value: 'ALL',
      },
      {
        text: 'SERVICE DESIGN',
        value: 'SERVICE DESIGN',
      },
      {
        text: 'DIGITAL PRODUCT',
        value: 'DIGITAL PRODUCT',
      },
      {
        text: 'DIGITAL BRANDING',
        value: 'DIGITAL BRANDING',
      },
      {
        text: 'EXPERIENCE DESIGN',
        value: 'EXPERIENCE DESIGN',
      },
      {
        text: 'DESIGN OPS',
        value: 'DESIGN OPS',
      },
    ];
  }, []);
  // const moreContent = useCallback(() => {
  //   setIncrement(increment => increment + 1);
  //   setShowCount(prevCount => prevCount + upCount);
  // }, []);
  const handleCategory = useCallback((e: React.MouseEvent<HTMLElement>) => {
    // setIncrement(0);
    // setShowCount(initailShowCount);
    setCategory(e.currentTarget.textContent as CategoryType);
  }, []);

  const selectedProjects = useMemo(() => {
    if (category === 'ALL') {
      return projects;
    } else {
      return projects?.filter(project =>
        project.node?.acf?.services?.some(service => service?.post_title === category)
      );
    }
  }, [category]);

  const pickUpProjects = useMemo(() => {
    if (category === 'ALL') {
      return pickups
        ?.map(pickup => projects?.filter(project => project?.node?.title?.rendered === pickup?.post_title))
        .flat()
        .slice(0, DISPLAY_COUNT_OF_PICKUP_PROJECTS); // FIXME: 適当に2個でスライスしているのでデザイン確定次第FIX
    } else {
      return selectedProjects?.filter(project => project?.node.acf?.pickup).slice(0, DISPLAY_COUNT_OF_PICKUP_PROJECTS); // FIXME: 適当に2個でスライスしているのでデザイン確定次第FIX
    }
  }, [selectedProjects]);

  const listProjects = useMemo(() => {
    return selectedProjects?.filter(project => !pickUpProjects?.includes(project));
  }, [selectedProjects]);

  // const showProjects = listProjects?.slice(0, showCount);
  const showProjects = listProjects;

  let isSp = false;
  if (typeof window !== 'undefined') isSp = window.innerWidth <= breakpoint;

  useEffect(() => {
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
      });
    });

    return () => {
      ParticleImageManager.delete();
    };
  }, []);

  return (
    <>
      <SEO title={'Projects'} />
      <ContentWrapper noSpPadding>
        <TitleSection>
          <EnHeading>Projects</EnHeading>
        </TitleSection>
        <ProjectArea>
          <FadeUpItem delay={0.6}>
            <ProjectCardCol col={2}>
              {pickUpProjects?.map((project, idx) => {
                return (
                  <ProjectCard
                    key={`pickup-${category}-${project?.node?.id}-${idx}`}
                    size={'large'}
                    spSize="small"
                    imgSpSize="full"
                    imgpath={project?.node?.acf?.thumb || `noimage/img-card-thumb.jpg`}
                    title={project?.node?.acf?.page_headline_ja || project?.node?.title?.rendered || 'NO TITLE'}
                    client={project?.node?.acf?.client_abbr?.ja || undefined}
                    work={project?.node?.acf?.product?.ja || undefined}
                    tags={project?.node?.acf?.outline as string[]}
                    to={`/projects/${project?.node?.slug}`}
                    delay={idx + 1}
                  />
                );
              })}
            </ProjectCardCol>
          </FadeUpItem>
          <FadeUpSection>
            <ProjectCardCol col={3}>
              {showProjects?.map((project, idx) => {
                return (
                  <ProjectCard
                    key={`list-${category}-${project.node.id}-${idx}`}
                    size={'small'}
                    spSize="small"
                    imgpath={project?.node?.acf?.thumb || `noimage/img-card-thumb.jpg`}
                    title={project?.node?.acf?.card_headline_ja || project?.node?.title?.rendered || 'NO TITLE'}
                    client={project?.node?.acf?.client_abbr?.ja || undefined}
                    work={project?.node?.acf?.product?.ja || undefined}
                    tags={project?.node?.acf?.outline as string[]}
                    to={`/projects/${project?.node?.slug}`}
                    // delay={idx + 1 - increment * upCount}
                    delay={idx + 1}
                  />
                );
              })}
            </ProjectCardCol>
          </FadeUpSection>
        </ProjectArea>
      </ContentWrapper>

      <InquiryBlock />
    </>
  );
};

export const pageQuery = graphql`
  query ProjectsIndex {
    site {
      siteMetadata {
        title
      }
    }
    allShifterServicesData(
      filter: { title: { rendered: { nin: ["dummy", null] } } }
      sort: { fields: acf___show_num, order: ASC }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
        }
      }
    }
    shifterPickupProjects: shifterPickupProjects(title: { rendered: { eq: "allpickupprojects" } }) {
      acf {
        pickup {
          post_title
        }
      }
    }
    allShifterProjectsJpData(
      sort: { fields: date, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } } }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          acf {
            page_headline_ja
            card_headline_ja
            client_abbr {
              en
              ja
            }
            services {
              post_title
            }
            thumb
            outline
            kv
            kv_sp
            product {
              ja
              en
            }
          }
          slug
        }
      }
    }
    allShifterProjectsData(
      sort: { fields: date, order: DESC }
      filter: { title: { rendered: { nin: ["dummy", null] } } }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          acf {
            page_headline_ja
            card_headline_ja
            client_abbr {
              en
              ja
            }
            services {
              post_title
            }
            thumb
            outline
            kv
            kv_sp
            product {
              ja
              en
            }
          }
          slug
        }
      }
    }
  }
`;

export default Pages;
